import React, { FunctionComponent, useEffect } from 'react';
import { Button, Form, Input, Select, Typography, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { Div } from '../../../../framework';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title } = Typography;
const { Option } = Select;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const ExperiencePage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const [form] = Form.useForm();

  useEffect(() => {
    setStep(6);
  });

  useEffect(() => {
    if (Object.keys(talent)) {
      const { dob, ...talentProps } = talent;
      form.setFieldsValue({
        ...talentProps,
        dob: moment(new Date(dob as string)),
      });
    }
  }, [talent]);

  const onFinish = async ({ experiences }: any) => {
    const values = {
      experiences: experiences ? experiences : [],
    };
    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile(values, talentId);
    dispatch(setPartialTalentProfile({ ...values }));
    const location = {
      pathname: '/talent/profile/kol',
      search: generateSearchParamString({ edit }),
      state,
    };

    history.push(location);
  };

  return (
    <Form form={form} initialValues={{ remember: true }} onFinish={onFinish}>
      <Title level={3}>Experience</Title>
      <Form.List name='experiences'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align='baseline'>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area ||
                    prevValues.sights !== curValues.sights
                  }
                >
                  <Form.Item
                    {...field}
                    label='Year'
                    name={[field.name, 'year']}
                    rules={[{ required: true, message: 'Missing year' }]}
                  >
                    <Select>
                      {Array.from<number, number>(
                        { length: 10 },
                        (item, index) => new Date().getFullYear() - index
                      ).map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label='Type'
                    name={[field.name, 'type']}
                    rules={[{ required: true, message: 'Missing type' }]}
                  >
                    <Select>
                      <Option key='digital' value='Digital Ads'>
                        Digital Ads
                      </Option>
                      <Option key='tvc' value='TVC'>
                        Television Commercial (TVC)
                      </Option>
                      <Option key='short-film' value='Short Film'>
                        Short Film
                      </Option>
                      <Option key='drama' value='Drama'>
                        Drama
                      </Option>
                      <Option key='film' value='Film'>
                        Film
                      </Option>
                      <Option key='other' value='Other'>
                        Other
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label='Title / Brand'
                    name={[field.name, 'name']}
                    rules={[{ required: true, message: 'Missing title/brand' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label='Role'
                    name={[field.name, 'role']}
                    rules={[{ required: true, message: 'Missing role' }]}
                  >
                    <Select>
                      <Option key='main' value='Main'>
                        Main
                      </Option>
                      <Option key='featured' value='Featured'>
                        Featured
                      </Option>
                      <Option key='featured extra' value='Featured Extra'>
                        Featured Extra
                      </Option>
                      <Option key='supporting' value='Supporting'>
                        Supporting
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label='Video link'
                    name={[field.name, 'link']}
                    rules={[
                      {
                        required: true,
                        message: 'Please provide a link for reference',
                      },
                      {
                        type: 'url',
                        message: "Hmmm.. This doesn't look like a link (URL)",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => add({ createdAt: new Date().toISOString() })}
                block
                icon={<PlusOutlined />}
              >
                Add Experience
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Div mb='l'>
        <Form.Item>
          {/* Submit button auto load block */}
          <Div mb='m'>
            <Button type='primary' htmlType='submit' block>
              Update &amp; Next
            </Button>
          </Div>
          <Button
            block
            onClick={() => {
              const location = {
                pathname: '/talent/profile/video',
                search: generateSearchParamString({ edit }),
                state,
              };

              history.push(location);
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Div>
    </Form>
  );
};

export default ExperiencePage;

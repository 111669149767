import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';
import { Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { Activity } from '../../../helper/type';
import { activityActionType } from '../../../helper/constants';
import { useActivityService } from '../../../service/activity.service';
import { RootState } from '../../../store/store';
import { setActivityFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import Invitation from './Invitation/Invitation';
import Confirmation from './Confirmation/Confirmation';

import './Activity.scss';

const TalentActivityPage: FunctionComponent = () => {
  useResetScroll();

  const { activityId } = useParams<{ activityId: string }>();
  const { activities } = useSelector((state: RootState) => state.user);
  const [activity, setActivity] = useState<Activity>();

  const activityService = useActivityService();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    dispatch(setActivityFlyoutOpen({ isOpen: false }));
    (async () => {
      let loadActivity = activities.pending.find(
        (activity) => activity.activityId === activityId
      );

      if (!loadActivity)
        loadActivity = await activityService.getActivity(activityId);

      setActivity(loadActivity);
    })();
  }, [activityId]);

  const renderContent = (act: Activity) => {
    if (
      [activityActionType.casting, activityActionType.fitting].includes(
        act.actionType
      )
    )
      return <Invitation activity={act} />;
    else if (act.actionType === activityActionType.hirerConfirmApplication)
      return <Confirmation activity={act} />;

    return null;
  };

  return (
    <Page className='page-talent-activity talent-page'>
      {activity ? (
        renderContent(activity)
      ) : (
        <Empty description="Invalid activity or you've already responded to this request." />
      )}
    </Page>
  );
};

export default TalentActivityPage;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Form, Input, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { scroller, Element } from 'react-scroll';
import { Rule } from 'antd/lib/form';
import { Div } from '../../../../framework';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import {
  malaysiaStates,
  originStates,
  countries,
  internationalCodes,
} from '../../../../../constant/region';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { removeEmptyStringProps } from '../../../../helper/object';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';
import { paymentMethods, banks } from '../../../../../constant/payment';
import { featureToggle } from '../../../../../config/app.config';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const socialIds = [
  'instagramId',
  'tiktokId',
  'douyinId',
  'facebookId',
  'youtubeId',
  'xiaohongshuId',
  'twitterId',
];

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const ContactInfoPage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const updateSocial = new URLSearchParams(search).get('update-social');
  const [form] = Form.useForm();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState<
    string | undefined
  >(talent.phoneNumberPrefix);
  const [paymentMethod, setPaymentMethod] = useState<string>();

  const socialRules: Rule[] = [
    {
      pattern: /^[a-zA-Z0-9_.-]{3,}$/,
      message: `Hmmm.. This doesn't look like a valid ID`,
    },
    {
      pattern: /[^-]+/,
      message: `Hmm.. If you don't have this social, just leave it blank`,
    },
  ];

  useEffect(() => {
    setStep(0);
  });

  useEffect(() => {
    if (Object.keys(talent)) {
      form.setFieldsValue(talent);

      featureToggle.invoice &&
        setPaymentMethod(talent.paymentMethod?.channelCode ?? 'MY_DUITNOW');
    }
  }, [talent]);

  useEffect(() => {
    if (updateSocial) {
      scroller.scrollTo('social-profiles', {
        duration: 700,
        delay: 100,
        smooth: true,
        offset: 50,
      });
    }
  }, [updateSocial]);

  const onPhoneNumberPrefixChange = (value: string) => {
    setPhoneNumberPrefix(value);
  };

  const checkEmptySocialIds = (values: any) => {
    socialIds.forEach((id) => {
      if (!values[id] || values[id].length === 0) {
        values[id] = null;
      }
    });
  };

  const checkSocialIdUpdated = (values: any) => {
    socialIds.forEach((id) => {
      // if the current talent value is same as the form value, don't send as part of update payload
      if (id in talent && (talent as any)[id] === values[id]) {
        delete values[id];
      }
    });
  };

  const onFinish = async ({ email, ...values }: any) => {
    const updatedValues = removeEmptyStringProps({ ...values });
    checkEmptySocialIds(updatedValues);
    checkSocialIdUpdated(updatedValues);
    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile(updatedValues, talentId);

    const hasSocialProfileUpdate =
      updatedValues.facebookId ||
      updatedValues.instagramId ||
      updatedValues.tiktokId ||
      updatedValues.douyinId ||
      updatedValues.youtubeId ||
      updatedValues.xiaohongshuId ||
      updatedValues.twitterId;

    dispatch(
      setPartialTalentProfile({
        ...updatedValues,
        ...(hasSocialProfileUpdate && { reviewSocialLinks: false }),
        ...(featureToggle.invoice &&
          updatedValues.paymentMethod?.channelCode && {
            reviewPaymentMethod: false,
          }),
      })
    );

    const location = {
      pathname: '/talent/profile/about',
      search: generateSearchParamString({ edit }),
      state,
    };

    history.push(location);
  };

  const phoneNumberPrefixSelector = (
    <Form.Item
      name='phoneNumberPrefix'
      noStyle
      rules={[
        {
          required: true,
          message: 'Please select phone number prefix',
        },
      ]}
    >
      <Select style={{ width: 150 }} onChange={onPhoneNumberPrefixChange}>
        {internationalCodes.map((code) => (
          <Option
            key={`${code.prefix} (${code.name})`}
            value={code.prefix}
          >{`${code.prefix} (${code.name})`}</Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Form form={form} onFinish={onFinish}>
      <Title level={3}>Contact Information</Title>
      <Form.Item
        name='name'
        rules={[
          {
            required: true,
            message: 'Please input your name',
          },
        ]}
      >
        <Input placeholder='Full Name' />
      </Form.Item>
      <Form.Item>
        <Input value={authUser.email} placeholder='Email' disabled />
      </Form.Item>
      {!featureToggle.invoice && (
        <Div mb='l'>
          <Form.Item
            name='phoneNumber'
            rules={[
              {
                required: true,
                message: 'Please input your phone number',
              },
              {
                pattern:
                  phoneNumberPrefix === '+60'
                    ? /^(0?1)[0-46-9]-*[0-9]{7,8}$/
                    : /^[0-9]{6,12}$/,
                message: "This phone number doesn't seems right",
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input
              addonBefore={phoneNumberPrefixSelector}
              placeholder='Mobile Number'
              type='number'
            />
          </Form.Item>
          <Paragraph type='secondary' style={{ fontSize: '0.9rem' }}>
            Must be a phone number reachable via WhatsApp
          </Paragraph>
        </Div>
      )}
      <Form.Item
        name='state'
        rules={[
          {
            required: true,
            message: 'Please select your residing state',
          },
        ]}
      >
        <Select placeholder='Residing State'>
          {malaysiaStates.map((state, index) => (
            <Option key={index} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='originState'
        rules={[
          {
            required: true,
            message: 'Please select your residing state',
          },
        ]}
      >
        <Select placeholder='Are you from peninsular or east Malaysia'>
          {originStates.map((state, index) => (
            <Option key={index} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='countryOfOrigin'
        rules={[
          {
            required: true,
            message: 'Please select your nationality',
          },
        ]}
      >
        <Select placeholder='Nationality'>
          {countries.map((country, index) => (
            <Option key={index} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {featureToggle.invoice && (
        <>
          <Title level={3}>Payment Information</Title>
          <Form.Item
            name={['paymentMethod', 'channelCode']}
            rules={[
              {
                required: true,
                message: 'Please select your payment method',
              },
            ]}
            initialValue={'MY_DUITNOW'}
          >
            <Select onChange={(val) => setPaymentMethod(val)}>
              {Object.keys(paymentMethods).map((key) => (
                <Option key={key} value={key}>
                  {paymentMethods[key as keyof typeof paymentMethods]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {form.getFieldValue(['paymentMethod', 'channelCode']) ===
            'MY_DUITNOW' && (
            <Div mb='l'>
              <Form.Item
                name={['paymentMethod', 'beneficieryName']}
                rules={[
                  {
                    required: true,
                    message: 'Missing beneficiery name.',
                  },
                ]}
                initialValue={
                  talent.paymentMethod?.beneficieryName ?? talent.name
                }
              >
                <Input placeholder='Beneficiery Name' />
              </Form.Item>
              <Form.Item
                name='phoneNumber'
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number',
                  },
                  {
                    pattern:
                      phoneNumberPrefix === '+60'
                        ? /^(0?1)[0-46-9]-*[0-9]{7,8}$/
                        : /^[0-9]{6,12}$/,
                    message: "This phone number doesn't seems right",
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  addonBefore={phoneNumberPrefixSelector}
                  placeholder='Mobile Number'
                  type='number'
                />
              </Form.Item>
              <Paragraph type='secondary' style={{ fontSize: '0.9rem' }}>
                Must be a phone number registered with DuitNow
              </Paragraph>
            </Div>
          )}
          <Form.Item
            hidden={
              form.getFieldValue(['paymentMethod', 'channelCode']) !== 'bank'
            }
            name='paymentBank'
            rules={[
              {
                required:
                  form.getFieldValue(['paymentMethod', 'channelCode']) ===
                  'bank',
                message: 'Please specify a bank',
              },
            ]}
          >
            <Select placeholder='Please specify a bank'>
              {Object.keys(banks).map((key) => (
                <Option key={key} value={key}>
                  {banks[key as keyof typeof banks]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            hidden={
              form.getFieldValue(['paymentMethod', 'channelCode']) !== 'bank'
            }
            name='paymentBankAccount'
            rules={[
              {
                required:
                  form.getFieldValue(['paymentMethod', 'channelCode']) ===
                  'bank',
                message: 'Please specify your account number',
              },
            ]}
          >
            <Input placeholder='Bank account number' />
          </Form.Item>
        </>
      )}

      <Element name='social-profiles'>
        <Title level={3}>Social Profiles</Title>
      </Element>
      <Form.Item name='instagramId' rules={socialRules}>
        <Input
          addonBefore='https://instagram.com/'
          placeholder='Instagram ID'
        />
      </Form.Item>
      <Form.Item name='tiktokId' rules={socialRules}>
        <Input addonBefore='https://tiktok.com/@' placeholder='TikTok ID' />
      </Form.Item>
      <Form.Item name='douyinId' rules={socialRules}>
        <Input addonBefore='https://douyin.com/user/' placeholder='Douyin ID' />
      </Form.Item>
      <Form.Item name='facebookId' rules={socialRules}>
        <Input addonBefore='https://facebook.com/' placeholder='Facebook ID' />
      </Form.Item>
      <Form.Item name='youtubeId' rules={socialRules}>
        <Input
          addonBefore='https://youtube.com/@'
          placeholder='YouTube Channel'
        />
      </Form.Item>
      <Form.Item name='xiaohongshuId' rules={socialRules}>
        <Input
          addonBefore='https://xiaohongshu.com/user/profile/'
          placeholder='Xiaohongshu (小红书) Profile ID'
        />
      </Form.Item>
      <Form.Item name='twitterId' rules={socialRules}>
        <Input addonBefore='https://twitter.com/' placeholder='Twitter ID' />
      </Form.Item>
      <Form.Item>
        {/* Submit button auto load block */}
        <Button type='primary' htmlType='submit' block>
          Update &amp; Next
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactInfoPage;

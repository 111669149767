import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  LinkOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Div } from '../../../framework';
import { getApplicantWithdrawn } from '../../../helper/activity/getApplicantActivities';
import { activityActionType } from '../../../helper/constants';
import { Activity, Project } from '../../../helper/type';
import { useActivityService } from '../../../service/activity.service';

import './AgendaCard.scss';

type Props = {
  activity: Activity;
  project: Partial<Project>;
  roleName: string;
};

const AgendaCard: FunctionComponent<Props> = ({
  activity,
  project,
  roleName,
}) => {
  const activityService = useActivityService();
  const scheduledDatetime = moment(activity?.scheduledDatetime);
  const [withdraw, setWithdraw] = useState<Activity>();

  useEffect(() => {
    (async () => {
      const { roleId, talentId } = activity;
      const { data } = await activityService.listRoleTalentActivity({
        roleId,
        talentId,
      });
      const mappedActivities = {
        [roleId]: { [talentId]: data },
      };
      setWithdraw(getApplicantWithdrawn(roleId, talentId, mappedActivities));
    })();
  }, []);

  return (
    <Div mv='l' className='talent-agenda-card' flex>
      <Div className='schedule-info'>
        <Div className='agenda-card-header' flex>
          <Div>
            <span className='header-title'>
              {project.title} - {roleName}
            </span>
          </Div>
        </Div>

        <Div className='project-details'>
          {activity.castingType === 'physical' ? (
            <Div pb='xs' className='bold' flex style={{ alignItems: 'center' }}>
              <EnvironmentOutlined className='icon' />
              <a
                href={`http://maps.google.com/maps?t=m&q=loc:${activity.castingLocationLat}+${activity.castingLocationLng}`}
                target='_blank'
                className='p-0 text-ellipsis'
                rel='noreferrer'
              >
                {activity.castingLocation}
              </a>
            </Div>
          ) : (
            <Div pb='xs' className='bold'>
              <EnvironmentOutlined className='icon' />
              {project.shootLocation || 'TBC'}
            </Div>
          )}
          <Div pb='xs' className='bold'>
            <ClockCircleOutlined className='icon' />
            <span className='scheduled-time'>
              {scheduledDatetime.fromNow()}
              {activity.actionType !==
              activityActionType.talentAcceptConfirmation
                ? ` (${scheduledDatetime.format('hh:mm a').toUpperCase()})`
                : ''}
            </span>
          </Div>
          <Div pb='xs' className='bold'>
            <VideoCameraOutlined className='icon' />
            <span className='activity-type'>
              {activity?.actionType ===
              activityActionType.talentAcceptConfirmation
                ? 'shooting'
                : activity?.actionType.toLowerCase()}{' '}
              {activity.castingType && `(${activity.castingType})`}
            </span>
          </Div>
          {activity.castingType === 'physical' && (
            <Div pb='xs' className='bold'>
              <PhoneOutlined className='icon' />
              <span>
                {activity.picContactPrefix}
                {activity.picContact}
              </span>
            </Div>
          )}
          {activity.castingType === 'virtual' && (
            <>
              <Div pb='xs' flex style={{ alignItems: 'center' }}>
                <LinkOutlined className='icon' />
                <a
                  href={activity.virtualMeetingLink}
                  target='_blank'
                  className='p-0 text-ellipsis'
                  rel='noreferrer'
                >
                  {activity.virtualMeetingLink}
                </a>
              </Div>
              <Div pb='xs'>
                {activity.castingBrief?.split('\n').map((str) => (
                  <>
                    {str}
                    <br />
                  </>
                ))}
              </Div>
            </>
          )}
        </Div>
      </Div>

      {withdraw && (
        <Div className='withdraw-reason' flex pv='m' ph='l'>
          Withdrawal Reason:
          <br />
          {withdraw.withdrawReason}
        </Div>
      )}
    </Div>
  );
};

export default AgendaCard;

import React, { FunctionComponent, useEffect } from 'react';
import talentProfileFormRoutes from '../../../../route/talentProfileForm.route';
import { TalentProfileSections as steps } from '../../../../page/Talent/Form/Form';
import { Div, Button } from '../../../../framework';
import { Link } from 'react-router-dom';
import './ProfileSection.scss';
import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setFlyoutOpen } from '../../../../store/app.slice';
import { RootState } from '../../../../store/store';

const { Title } = Typography;

const ProfileSection: FunctionComponent = () => {
  const sections = talentProfileFormRoutes
    .slice(0, steps.length)
    .map((prop, index) => {
      const { path } = prop;

      return (
        <Link
          key={index}
          className='section-list-item'
          to={{
            pathname: path as string,
            search: '?edit=true',
          }}
        >
          {steps[index].title}
        </Link>
      );
    });

  const dispatch = useDispatch();
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  return (
    <div className='page-profile-section'>
      <Button
        block
        rounded
        to={`/t/${isImpersonator ? impersonateId : authUser.username}`}
        mb='m'
      >
        View My Profile
      </Button>
      <Title level={3}>Update Profile</Title>
      <Div className='section-list'>{sections}</Div>
    </div>
  );
};

export default ProfileSection;

import { Dropdown, MenuProps, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TalentProfileSections as steps } from './Form';
import talentRoutes from '../../../route/talentProfileForm.route';
import React, { FunctionComponent } from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { Div } from '../../../framework';
import './FormSectionDropdown.scss';

const { Title } = Typography;

type Props = {
  currentStep: number;
};

const FormSectionDropdown: FunctionComponent<Props> = ({ currentStep }) => {
  const items: MenuProps['items'] = talentRoutes
    .slice(0, steps.length)
    .map((prop, key) => {
      const { path } = prop;

      return {
        key,
        label: (
          <Link
            to={{
              pathname: path as string,
              search: '?edit=true',
            }}
          >
            {steps[key].title}
          </Link>
        ),
      };
    });

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(menu) => (
        <div>{React.cloneElement(menu as React.ReactElement)}</div>
      )}
      trigger={['click']}
    >
      <Div className='component-section-dropdown-title' flex>
        <Title level={4}>{steps[currentStep].title} </Title>
        <CaretDownFilled style={{ fontSize: '20px' }} />
      </Div>
    </Dropdown>
  );
};

export default FormSectionDropdown;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Radio, Form, Input, Typography, RadioChangeEvent } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import { Talent } from '../../../../helper/type';
import { Div } from '../../../../framework';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title, Paragraph } = Typography;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const AgentPage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const [form] = Form.useForm();
  const [hasAgency, setHasAgency] = useState(false);

  useEffect(() => {
    setStep(8);
  });

  useEffect(() => {
    if (talent?.agency && Object.keys((talent as Talent).agency).length) {
      form.setFieldsValue(talent.agency);
      setHasAgency(true);
    }
  }, [talent]);

  const onHasAgencyChange = (event: RadioChangeEvent) => {
    setHasAgency(event.target.value);
  };

  const onFinish = async (values: any) => {
    const talentId = impersonator.impersonateId || authUser.username;
    const updatedValue = {
      agency: hasAgency ? values : {},
    };

    await talentService.updateProfile(updatedValue, talentId);
    dispatch(setPartialTalentProfile(updatedValue));
    const location = {
      pathname: '/talent/profile/job-preference',
      search: generateSearchParamString({ edit }),
      state,
    };

    history.push(location);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Title level={3}>Artiste / KOL Management Company</Title>
      <Div mb='xl'>
        <Radio.Group value={hasAgency} onChange={onHasAgencyChange}>
          <Paragraph type='secondary'>
            You may include your manager details if you are working with Artiste
            Management Company or KOL management company.
          </Paragraph>
          <Paragraph>
            Are you working with an Artiste Management Company or a KOL
            management company?
          </Paragraph>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Div>
      <Form.Item
        name='name'
        rules={[
          {
            required: hasAgency,
            message: 'Please input manager name',
          },
        ]}
      >
        <Input placeholder='Manager Name' disabled={!hasAgency} />
      </Form.Item>
      <Form.Item
        name='email'
        rules={[
          {
            required: hasAgency,
            message: 'Please input agency email',
          },
          {
            type: 'email',
            message: 'This is not a valid email address',
          },
        ]}
      >
        <Input placeholder='Manager Email' disabled={!hasAgency} />
      </Form.Item>
      <Form.Item
        name='phoneNumber'
        rules={[
          {
            required: hasAgency,
            message: 'Please input agency phone number',
          },
          {
            pattern: /^(0?1)[0-46-9]-*[0-9]{7,8}$/,
            message: 'Not a valid phone number',
          },
        ]}
      >
        <Input
          addonBefore='+60'
          placeholder='Manager Phone Number'
          disabled={!hasAgency}
        />
      </Form.Item>
      <Form.Item
        name='company'
        rules={[
          {
            required: hasAgency,
            message: 'Please input agency phone number',
          },
        ]}
      >
        <Input
          placeholder='Artiste / KOL Management Company Name'
          disabled={!hasAgency}
        />
      </Form.Item>
      <Form.Item name='website'>
        <Input
          placeholder='Artiste / KOL Management Company Website'
          disabled={!hasAgency}
        />
      </Form.Item>
      <Div mb='l'>
        <Form.Item>
          {/* Submit button auto load block */}
          <Div mb='m'>
            <Button type='primary' htmlType='submit' block>
              Update &amp; Next
            </Button>
          </Div>
          <Button
            block
            onClick={() => {
              const location = {
                pathname: '/talent/profile/kol',
                search: generateSearchParamString({ edit }),
                state,
              };

              history.push(location);
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Div>
    </Form>
  );
};

export default AgentPage;

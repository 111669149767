import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Select, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import * as info from '../../../../../constant/personalInfo';
import * as skill from '../../../../../constant/skill';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { Div } from '../../../../framework';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title, Link, Paragraph } = Typography;
const { Option, OptGroup } = Select;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const SkillPage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const [form] = Form.useForm();

  const [skills, setSkills] = useState<string[]>([
    'Dance',
    'Sing',
    'Voiceover',
    'Host',
    'Cry on the spot',
    'Guitar',
    'Piano',
    'Martial Art',
    'Screenwriting',
  ]);
  const [name, setName] = useState('');

  useEffect(() => {
    setStep(2);
  });

  useEffect(() => {
    if (Object.keys(talent)) {
      form.setFieldsValue(talent);
      if (talent?.skills?.length) {
        setSkills(_.uniq([...skills, ...talent.skills]));
      }
    }
  }, [talent]);

  const onFinish = async (values: any) => {
    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile(values, talentId);
    dispatch(setPartialTalentProfile({ ...values }));

    const location = {
      pathname: '/talent/profile/media',
      search: generateSearchParamString({ edit }),
      state,
    };

    history.push(location);
  };

  const addSkill = (e: React.MouseEvent) => {
    e.preventDefault();
    setSkills([...skills, name]);
    setName('');
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Title level={3}>Skills</Title>
      <Form.Item
        name='languages'
        label='Languages'
        style={{ marginBottom: '0' }}
        rules={[
          {
            required: true,
            min: 1,
            type: 'array',
            message: 'Please choose at least 1',
          },
        ]}
      >
        <Select mode='multiple' placeholder='Choose your languages'>
          <OptGroup label='Standard'>
            {skill.languages.standard.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </OptGroup>
          <OptGroup label='Chinese'>
            {skill.languages.chinese.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </OptGroup>
          <OptGroup label='Indian'>
            {skill.languages.indian.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </OptGroup>
          <OptGroup label='Malay'>
            {skill.languages.malay.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </OptGroup>
          <OptGroup label='Native'>
            {skill.languages.native.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </OptGroup>
          <OptGroup label='Foreign'>
            {skill.languages.foreign.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </OptGroup>
        </Select>
      </Form.Item>
      <Paragraph type='secondary' style={{ fontSize: '14px' }}>
        Please choose only languages and dialects you can speak fluently
      </Paragraph>
      <Form.Item
        name='skills'
        label='Other Skills'
        style={{ marginBottom: '0' }}
      >
        <Select
          mode='multiple'
          placeholder='e.g. Dance, Sing, Voiceover'
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space align='center' style={{ padding: '0 8px 4px' }}>
                <Input
                  placeholder='Please enter item'
                  value={name}
                  onChange={onNameChange}
                />
                <Link onClick={addSkill} style={{ whiteSpace: 'nowrap' }}>
                  <PlusOutlined /> Add talent
                </Link>
              </Space>
            </>
          )}
        >
          {skills.map((skill) => (
            <Option key={skill} value={skill}>
              {skill}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Paragraph type='secondary' style={{ fontSize: '14px' }}>
        For example, surfing, swimming, skiing, roller-skate, hip hop, ballet
        playing music instruments like guitar, piano, trumpet, Muay Thai, MMA,
        Wing Chun, crying (can cry if the storyboard required), voiceover. You
        name it, as long as you could perform it well! As you might already
        know, some of the shooting jobs required special talents.
      </Paragraph>
      <Form.Item
        name='roles'
        label='Roles'
        rules={[
          {
            required: true,
            min: 1,
            type: 'array',
            message: 'Please choose at least 1',
          },
          {
            max: 3,
            type: 'array',
            message: 'Please choose up to 3 only',
          },
        ]}
      >
        <Select
          mode='multiple'
          placeholder='Choose up to 3 that best describe you'
        >
          {info.role.map((value) => (
            <Option key={value.key} value={value.key}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='bio'
        label='Bio'
        rules={[
          {
            required: true,
            message: 'Please fill in bio',
          },
          {
            max: 150,
            message: 'Keep your introduction shorter',
          },
        ]}
      >
        <Input.TextArea
          placeholder='Introduce yourself in 150 characters'
          showCount
          autoSize={{
            minRows: 3,
            maxRows: 4,
          }}
        />
      </Form.Item>
      <Paragraph type='secondary' style={{ fontSize: '14px' }}>
        Example Bio: Passionate and highly dedicated acting talent that can
        carry comedy and profession roles
      </Paragraph>
      <Div mb='l'>
        <Form.Item>
          {/* Submit button auto load block */}
          <Div mb='m'>
            <Button type='primary' htmlType='submit' block>
              Update &amp; Next
            </Button>
          </Div>
          <Button
            block
            onClick={() => {
              const location = {
                pathname: '/talent/profile/about',
                search: generateSearchParamString({ edit }),
                state,
              };

              history.push(location);
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Div>
    </Form>
  );
};

export default SkillPage;

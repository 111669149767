import { Empty } from 'antd';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonProjectCard from '../../../../component/Skeleton/ProjectCard';
import { Div } from '../../../../framework';
import { JobHistory } from '../../../../helper/type';
import getApplicationViewCount from '../../../../helper/activity/getApplicationViewCount';
import { useApplicantStatus } from '../../../../hook/useApplicantStatus';
import { useActivityService } from '../../../../service/activity.service';
import { useJobService } from '../../../../service/job.service';
import { setMenuMode } from '../../../../store/app.slice';
import { setActivities } from '../../../../store/talent.slice';
import { RootState } from '../../../../store/store';
import ApplicationCard from '../ApplicationCard';

const ApplicationDetailPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { roleId } = useParams<{ roleId: string }>();
  const { talent } = useSelector((state: RootState) => state.user);
  const { loadStack } = useSelector((state: RootState) => state.app);
  const { mappedActivities } = useSelector((state: RootState) => state.talent);
  const [data, setData] = useState<JobHistory>();

  const { getLatestActivity, getApplicantStatus } =
    useApplicantStatus('talent');

  const activityService = useActivityService();
  const jobService = useJobService();

  const isLoadingApplication =
    loadStack.includes(`job/applicant/${roleId}/${talent.id}/get`) ||
    loadStack.includes('job/role/get') ||
    loadStack.includes('activity/role/talent/get');

  const applicationData = useMemo(() => {
    if (!data || !talent.id) return;

    const latestActivity = getLatestActivity(roleId, talent.id);
    return {
      ...data,
      latestActivity,
      applicantStatusKey:
        getApplicantStatus({ activity: latestActivity }) ?? 'none',
      viewCount: getApplicationViewCount(mappedActivities[roleId] ?? []),
    };
  }, [data, mappedActivities]);

  const loadData = async () => {
    if (!roleId || !talent.id) return;

    const res = await jobService.getApplicant(roleId, talent.id);
    if (res.status === 'withdrawn') return;

    const [fetchRole, fetchActivity] = await Promise.allSettled([
      jobService.getRole(roleId),
      activityService.listRoleTalentActivity({
        roleId,
        talentId: talent.id,
      }),
    ]);

    if (fetchRole.status === 'fulfilled') {
      const { project, ...role } = fetchRole.value;
      setData({ ...res, role, project });
    }

    if (fetchActivity.status === 'fulfilled') {
      dispatch(setActivities({ roleId, activities: fetchActivity.value.data }));
    }
  };

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return !isLoadingApplication && !data ? (
    <Empty description="Job application not found or you've already responded to this request." />
  ) : (
    <Div style={{ width: '100%' }}>
      <SkeletonProjectCard key={roleId} loading={isLoadingApplication}>
        {applicationData && (
          <ApplicationCard key={roleId} job={applicationData} allowWithdraw />
        )}
      </SkeletonProjectCard>
    </Div>
  );
};

export default ApplicationDetailPage;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Empty, Spin } from 'antd';
import { Div } from '../../../framework';
import { Activity } from '../../../helper/type';
import { useActivityService } from '../../../service/activity.service';
import { RootState } from '../../../store/store';
import { setActivities } from '../../../store/user.slice';
import PendingActivityCard from './PendingActivityCard';

import './PendingTabContent.scss';

const PendingTabContent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const activityService = useActivityService();
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { loadStack } = useSelector((state: RootState) => state.app);

  const [data, setData] = useState<Activity[]>([]);

  const isLoading = loadStack.includes('activity/talent/pending');
  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const talentId = authUser.username || (isImpersonator ? impersonateId : '');

  useEffect(() => {
    (async () => {
      const { data: result } = await activityService.listTalentActivities(
        'pending',
        talentId
      );
      if (result.pending) {
        const pending: Activity[] = result.pending;
        setData(pending.sort((a, b) => b.createdAt.localeCompare(a.createdAt)));
        dispatch(setActivities({ pending }));
      }
    })();
  }, []);

  return (
    <Div className='pending-application-tab'>
      <Spin spinning={isLoading} style={{ margin: '0.5rem auto' }}>
        {data.map((activity, index) => (
          <PendingActivityCard
            key={activity.activityId + index}
            activity={activity}
          />
        ))}
        {data.length === 0 ? (
          <Div className='zero-state'>
            <Empty description={<>No pending item</>} />
          </Div>
        ) : (
          <Divider style={{ margin: '2rem auto' }}>
            That&apos;s all pending items!
          </Divider>
        )}
      </Spin>
    </Div>
  );
};

export default PendingTabContent;

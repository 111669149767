import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { Div } from '../../../../framework';
import ProfilePhotoPicker from '../../../../component/ProfilePhotoPicker/ProfilePhotoPicker';
import { GalleryItem } from 'ui/client/src/helper/type';
import { useTalentService } from '../../../../service/talent.service';
import {
  setPartialTalentProfile,
  setProfilePicture,
} from '../../../../store/user.slice';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title, Paragraph } = Typography;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const ProfilePicturePage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const [profilePhoto, setProfilePhoto] = useState<GalleryItem>();

  useEffect(() => {
    setStep(4);
  }, []);

  const handleSubmit = async () => {
    const values = {
      profilePhoto: profilePhoto?.original,
      profilePhotoThumbnail: profilePhoto?.thumbnail,
    };
    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile(values, talentId);
    dispatch(setPartialTalentProfile({ ...values }));

    if (profilePhoto?.thumbnail) {
      dispatch(
        setProfilePicture({ profilePictureUrl: profilePhoto.thumbnail })
      );
    }
    const location = {
      pathname: '/talent/profile/video',
      search: generateSearchParamString({ edit }),
      state,
    };

    history.push(location);
  };

  return (
    <Div>
      <Title level={3}>Choose Profile Picture</Title>
      <Paragraph type='secondary'>
        Pick one photo as your profile picture
      </Paragraph>
      <ProfilePhotoPicker
        defaultValue={talent?.gallery
          ?.filter((item) => item.type === 'photo')
          .findIndex((item) => item.original === talent.profilePhoto)}
        gallery={talent.gallery?.filter((item) => item.type === 'photo')}
        onChange={setProfilePhoto}
      />
      <Div mv='l'>
        <Div mb='m'>
          <Button
            disabled={!profilePhoto}
            type='primary'
            block
            onClick={handleSubmit}
          >
            Update &amp; Next
          </Button>
        </Div>
        <Button
          block
          onClick={() => {
            const location = {
              pathname: '/talent/profile/media',
              search: generateSearchParamString({ edit }),
              state,
            };

            history.push(location);
          }}
        >
          Back
        </Button>
      </Div>
    </Div>
  );
};

export default ProfilePicturePage;

import { Tabs } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { setMenuMode } from '../../../store/app.slice';
import ApplicationTabContent from './ApplicationTabContent';
import UnpaidTabContent from './UnpaidTabContent';
import { featureToggle } from '../../../../config/app.config';

import './Application.scss';

const { TabPane } = Tabs;

const ApplicationPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  useResetScroll();

  const [tab, setTab] = useState('current');

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    setTab(new URLSearchParams(search).get('tab') ?? 'current');
  }, []);

  return (
    <Page className='page-job-history talent-page'>
      <Tabs activeKey={tab} onChange={setTab} centered>
        <TabPane tab='Current' key='current'>
          <ApplicationTabContent type='current' />
        </TabPane>
        {featureToggle.invoice && (
          <TabPane tab='Unpaid' key='unpaid'>
            <UnpaidTabContent />
          </TabPane>
        )}
        <TabPane tab='Past' key='past'>
          <ApplicationTabContent type='past' />
        </TabPane>
      </Tabs>
    </Page>
  );
};

export default ApplicationPage;

import React, { FunctionComponent, useEffect } from 'react';
import { Button, Form, InputNumber, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Div } from '../../../../framework';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import {
  setPartialTalentProfile,
  setIsTalentOnboarded,
} from '../../../../store/user.slice';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title } = Typography;
const { Option } = Select;
type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const JobPreferencePage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const [form] = Form.useForm();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );

  const { minimumDailyWage, acceptRoles } = talent;

  const isAlreadyOnboarded =
    acceptRoles && acceptRoles.length > 0 && minimumDailyWage !== undefined;

  useEffect(() => {
    setStep(9);
  });

  useEffect(() => {
    if (Object.keys(talent)) {
      form.setFieldsValue(talent);
    }
  }, [talent]);

  const onFinish = async (values: any) => {
    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile(values, talentId);
    dispatch(setPartialTalentProfile({ ...values }));
    dispatch(setIsTalentOnboarded({ isOnboarded: true }));
    history.push(`/t/${talentId}`, {
      isAlreadyOnboarded,
    });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Title level={3}>Job Preference</Title>
      <Form.Item
        name='acceptRoles'
        label='Accept Roles'
        rules={[
          {
            required: true,
            min: 1,
            type: 'array',
            message: 'Please choose at least 1',
          },
        ]}
      >
        <Select mode='multiple' placeholder='What are the roles you accept?'>
          <Option value='main'>Main</Option>
          <Option value='featured'>Featured / Supporting</Option>
          <Option value='featured extra'>Featured Extra</Option>
          <Option value='background'>Background</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Minimum Daily Wage (RM)'
        name='minimumDailyWage'
        rules={[
          {
            required: true,
            message: 'Please input minimum daily wage',
          },
          {
            type: 'number',
            min: 100,
            message: 'Enter an amount that is at least RM 100',
          },
        ]}
      >
        <InputNumber placeholder='What is your minimum daily wage for a shoot?' />
      </Form.Item>
      <Div mb='l'>
        <Form.Item>
          {/* Submit button auto load block */}
          <Div mb='m'>
            <Button type='primary' htmlType='submit' block>
              Update
            </Button>
          </Div>
          <Button
            block
            onClick={() => {
              const location = {
                pathname: '/talent/profile/agent',
                search: generateSearchParamString({ edit }),
                state,
              };

              history.push(location);
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Div>
    </Form>
  );
};

export default JobPreferencePage;

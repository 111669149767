import React, { FunctionComponent, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Typography,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTalentService } from '../../../../service/talent.service';
import { RootState } from '../../../../store/store';
import * as info from '../../../../../constant/personalInfo';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import { Div } from '../../../../framework';
import { useResetScroll } from '../../../../helper/hook';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

const { Title } = Typography;
const { Option } = Select;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const AboutMePage: FunctionComponent<Props> = ({ setStep }) => {
  useResetScroll();
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const edit = new URLSearchParams(search).get('edit');
  const [form] = Form.useForm();

  useEffect(() => {
    setStep(1);
  });

  useEffect(() => {
    if (Object.keys(talent)) {
      const {
        dob,
        playableAgeFrom,
        playableAgeTo,
        playableAge,
        ...talentProps
      } = talent;
      form.setFieldsValue({
        ...talentProps,
        dob: dob ? moment(new Date(dob as string)) : undefined,
        playableAgeFrom: playableAgeFrom ? playableAgeFrom : playableAge?.from,
        playableAgeTo: playableAgeTo ? playableAgeTo : playableAge?.to,
      });
    }
  }, [talent]);

  const onFinish = async ({
    dob,
    playableAgeFrom,
    playableAgeTo,
    ...values
  }: any) => {
    const updatedValues = {
      dob: dob.toISOString(),
      playableAgeFrom,
      playableAgeTo,
      playableAge: {
        from: playableAgeFrom,
        to: playableAgeTo,
      },
      ...values,
    };

    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile(updatedValues, talentId);
    dispatch(setPartialTalentProfile(updatedValues));
    const location = {
      pathname: '/talent/profile/skill',
      search: generateSearchParamString({ edit }),
      state,
    };
    history.push(location);
  };

  return (
    <Form form={form} initialValues={{ remember: true }} onFinish={onFinish}>
      <Title level={3}>About Me</Title>
      <Form.Item
        name='gender'
        label='Gender'
        rules={[
          {
            required: true,
            message: 'Please select your gender',
          },
        ]}
      >
        <Select placeholder='Choose your gender'>
          {info.gender.map((value) => (
            <Option key={value.key} value={value.key}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='dob'
        label='Date of Birth'
        rules={[
          {
            required: true,
            message: 'Please input your date of birth',
          },
        ]}
      >
        <DatePicker placeholder='Choose your date of birth' />
      </Form.Item>
      <Form.Item
        label='Visual Age (From)'
        name='playableAgeFrom'
        rules={[
          {
            required: true,
            message: 'Please input visual age (from)',
          },
        ]}
      >
        <InputNumber
          min={0}
          max={120}
          placeholder='from'
          addonAfter='years old'
        />
      </Form.Item>
      <Form.Item
        label='Visual Age (To)'
        name='playableAgeTo'
        rules={[
          {
            required: true,
            message: 'Please input visual age (to)',
          },
        ]}
      >
        <InputNumber
          min={0}
          max={120}
          placeholder='to'
          addonAfter='years old'
        />
      </Form.Item>
      <Form.Item
        name='race'
        label='Race'
        rules={[
          {
            required: true,
            message: 'Please select your race',
          },
        ]}
      >
        <Select placeholder='Choose your race'>
          {info.race.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='ethnicity'
        label='Ethnicity'
        rules={[
          {
            required: true,
            message: 'Please select your ethnicity',
          },
        ]}
      >
        <Select placeholder='Choose your ethnicity'>
          {info.ethnicity.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='weight'
        label='Weight'
        rules={[
          {
            required: true,
            message: 'Please input your weight',
          },
          {
            min: 10,
            type: 'number',
            message: 'Please make sure your measurement is in KG',
          },
          {
            max: 300,
            type: 'number',
            message: 'Please make sure your measurement is in KG',
          },
        ]}
      >
        <InputNumber placeholder='Input number in KG' addonAfter='KG' />
      </Form.Item>
      <Form.Item
        name='height'
        label='Height'
        rules={[
          {
            required: true,
            message: 'Please input your height',
          },
          {
            min: 75,
            type: 'number',
            message: 'Please make sure your measurement is in CM',
          },
          {
            max: 275,
            type: 'number',
            message: 'Please make sure your measurement is in CM',
          },
        ]}
      >
        <InputNumber placeholder='Input number in cm' addonAfter='CM' />
      </Form.Item>
      <Form.Item
        name='chestSize'
        label='Chest Size'
        rules={[
          {
            required: true,
            message: 'Please input your chest size',
          },
          {
            min: 18,
            type: 'number',
            message: 'Please make sure your measurement is in inch',
          },
          {
            max: 60,
            type: 'number',
            message: 'Please make sure your measurement is in inch',
          },
        ]}
      >
        <InputNumber placeholder='Input number in inch' addonAfter='inch' />
      </Form.Item>
      <Form.Item
        name='waistSize'
        label='Waist Size'
        rules={[
          {
            required: true,
            message: 'Please input your chest size',
          },
          {
            min: 18,
            type: 'number',
            message: 'Please make sure your measurement is in inch',
          },
          {
            max: 60,
            type: 'number',
            message: 'Please make sure your measurement is in inch',
          },
        ]}
      >
        <InputNumber placeholder='Input number in inch' addonAfter='inch' />
      </Form.Item>
      <Form.Item
        name='hipSize'
        label='Hip Size'
        rules={[
          {
            required: true,
            message: 'Please input your hip size',
          },
          {
            min: 18,
            type: 'number',
            message: 'Please make sure your measurement is in inch',
          },
          {
            max: 60,
            type: 'number',
            message: 'Please make sure your measurement is in inch',
          },
        ]}
      >
        <InputNumber placeholder='Input number in inch' addonAfter='inch' />
      </Form.Item>
      <Form.Item
        name='bodyType'
        label='Body Type'
        rules={[
          {
            required: true,
            message: 'Please select your body type',
          },
        ]}
      >
        <Select placeholder='Choose your body type'>
          {info.bodyType.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='shirtSize'
        label='Shirt Size'
        rules={[
          {
            required: true,
            message: 'Please select your shirt size',
          },
        ]}
      >
        <Select placeholder='Choose your shirt size'>
          {info.shirtSize.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='pantSize'
        label='Pant Size'
        rules={[
          {
            required: true,
            message: 'Please select your pant size',
          },
        ]}
      >
        <Select placeholder='Choose your pant size'>
          {info.shirtSize.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='shoeSize'
        label='Shoe Size'
        rules={[
          {
            required: true,
            message: 'Please input your shoe size',
          },
        ]}
      >
        <Input
          placeholder='Choose your shoe size'
          type='number'
          suffix='UK Size'
        />
      </Form.Item>
      <Form.Item
        name='eyeColor'
        label='Eye Color'
        rules={[
          {
            required: true,
            message: 'Please select your eye color',
          },
        ]}
      >
        <Select placeholder='Choose your eye color'>
          {info.eyeColor.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name='hairColor'
        label='Hair Color'
        rules={[
          {
            required: true,
            message: 'Please select hair color',
          },
        ]}
      >
        <Select placeholder='Choose your hair color'>
          {info.hairColor.map((value, index) => (
            <Option key={index} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Div mb='l'>
        <Form.Item>
          {/* Submit button auto load block */}
          <Div mb='m'>
            <Button type='primary' htmlType='submit' block>
              Update &amp; Next
            </Button>
          </Div>
          <Button
            block
            onClick={() => {
              const location = {
                pathname: '/talent/profile/contact',
                search: generateSearchParamString({ edit }),
                state,
              };
              history.push(location);
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Div>
    </Form>
  );
};

export default AboutMePage;

import React, { FC } from 'react';
import { Typography } from 'antd';
import { Div } from '../../framework/Div/Div';
import { Button } from '../../framework';
import { TALENT_ROUTES } from '../../route/constant';

import './PromptFooter.scss';

const { Title } = Typography;

const Footer: FC = () => {
  return (
    <Div className='prompt-component-footer'>
      <Div noGrow className='step-guide-box'>
        <Title level={2} style={{ color: '#fff', textAlign: 'center' }}>
          How to add app to home screen
        </Title>
        <Div className='step-guide'>
          <img src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/step_1.png' />
        </Div>
        <Div>
          <img
            className='step-guide'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/step_2.png'
          />
        </Div>
        <Div>
          <img
            className='step-guide'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/step_3.png'
          />
        </Div>
        <Div>
          <img
            className='step-guide'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/step_4.png'
          />
        </Div>
      </Div>

      <Div className='footer-btn'>
        <Button block href={TALENT_ROUTES.DASHBOARD}>
          Done
        </Button>
      </Div>
    </Div>
  );
};

export default Footer;

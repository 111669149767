import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Div } from '../../../../framework';
import { openModal } from '../../../../store/app.slice';

import './MealForm.scss';

type Props = {
  maxAmount: number;
  uploadedReceipt: Array<{ id: string; file?: File; url?: string }>;
  mealAmount: number;
  onUploadReceipt: (file: File) => void;
  onRemoveReceipt: (id: string) => void;
  onChangeMealAmount: (val: number) => void;
  disabled?: boolean;
};

const MealForm: FC<Props> = ({
  maxAmount,
  uploadedReceipt,
  mealAmount,
  onUploadReceipt,
  onRemoveReceipt,
  onChangeMealAmount,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [previews, setPreviews] = useState<Record<string, string>>({});
  const [error, setError] = useState('');

  useEffect(() => {
    const receiptPreviews: Record<string, string> = {};
    uploadedReceipt.forEach(({ id, file, url = '' }) => {
      if (!previews[id])
        receiptPreviews[id] = file ? URL.createObjectURL(file) : url;
    });
    setPreviews({ ...previews, ...receiptPreviews });
  }, [uploadedReceipt]);

  const isValidReceiptImage = (image: File) => {
    const fileType = image.type
      ? image.type.split('image/').pop()
      : image.name.split('.').pop();

    const validFormat = fileType && ['jpeg', 'png', 'heic'].includes(fileType);
    if (!validFormat) {
      setError('Invalid image format.');
      return false;
    }

    const validSize = image.size <= 1024 * 1024;
    if (!validSize) {
      setError('File size exceed limit: 1MB');
      return false;
    }

    setError('');
    return true;
  };

  const openGallery = (index: number) => {
    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: uploadedReceipt.map((receipt) => ({
              original: previews[receipt.id],
              thumbnail: previews[receipt.id],
            })),
            index,
          },
        },
      })
    );
  };

  return (
    <Div className='div-meal-form'>
      <Div mv='l'>
        <Div className='bold' mb='m'>
          Receipt
        </Div>
        {uploadedReceipt.map((receipt, idx) => (
          <Div
            key={`receipt-${receipt.id}`}
            flex
            className='div-receipt-upload'
          >
            <Div
              flex
              className='preview-wrapper'
              onClick={() => openGallery(idx)}
            >
              <img src={previews[receipt.id]} className='img-preview' />
              <span className='img-preview-name'>
                {receipt.file?.name ?? 'image'}
              </span>
            </Div>
            {!disabled && (
              <Button ghost onClick={() => onRemoveReceipt(receipt.id)}>
                <CloseOutlined style={{ color: 'red' }} />
              </Button>
            )}
          </Div>
        ))}
        {!disabled && (
          <Div>
            <Div mt='l' mb='m'>
              <label className='component-button --solid lbl-upload-receipt'>
                Upload Image
                <input
                  type='file'
                  accept='image/jpeg, image/png, .heic'
                  hidden
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) return;
                    if (!isValidReceiptImage(e.target.files[0])) return;

                    onUploadReceipt(e.target.files[0]);
                  }}
                />
              </label>
            </Div>
            <Div className='text-red'>{error}</Div>
            <span className='small block'>
              Supported formats: JPG, PNG, HEIC
              <br />
              File size limit: 1MB
            </span>
          </Div>
        )}
      </Div>
      <Div>
        <Div className='bold'>Amount</Div>
        <InputNumber
          min={0}
          value={mealAmount}
          className='w-full'
          addonBefore='MYR'
          onChange={(val) => onChangeMealAmount(val ?? 0)}
          disabled={disabled}
        />
        {!disabled && mealAmount > maxAmount && (
          <Div className='text-red'>
            Amount exceeded maximum claim allowed! (max: MYR {maxAmount})
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default MealForm;

import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { BottomNavigation, Div, Page } from '../../../framework';
import Menu from '../../../component/Menu/Menu';
import {
  setImpersonator,
  setIsTalentOnboarded,
  setPartialTalentProfile,
  setProfilePicture,
  setPartialSettings,
  setActivities,
} from '../../../store/user.slice';
import { setApplications } from '../../../store/talent.slice';
import talentRoutes from '../../../route/talent.routes';
import { useJobService } from '../../../service/job.service';
import { useTalentService } from '../../../service/talent.service';
import { useSettingsService } from '../../../service/settings.service';
import { Activity, Talent } from '../../../helper/type';
import { useNotificationService } from '../../../service/notification.service';
import { getSubscription } from '../../../serviceWorkerRegistration';
import { setIsSubscribeNotif } from '../../../store/app.slice';
import OnboardingBanner from '../../../component/OnboardingBanner/OnboardingBanner';
import InstallBanner from '../../../component/InstallBanner/InstallBanner';
import { AUTH_ROUTES } from '../../../route/constant';
import { useActivityService } from '../../../service/activity.service';

import './TalentDashboard.scss';

const TalentDashboardPage: FunctionComponent = () => {
  const { isTalentOnboarded, impersonator, authUser } = useSelector(
    (state: RootState) => state.user
  );

  const { isSubscribeNotif, isPWA } = useSelector(
    (state: RootState) => state.app
  );

  const dispatch = useDispatch();

  const activityService = useActivityService();
  const jobService = useJobService();
  const talentService = useTalentService();
  const settingsService = useSettingsService();
  const notificationService = useNotificationService();

  const { search } = useLocation();
  const history = useHistory();

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const talentId = authUser.username || (isImpersonator ? impersonateId : '');

  useEffect(() => {
    const impersonateId = new URLSearchParams(search).get('imid') as string;
    const impersonateKey = new URLSearchParams(search).get('imkey') as string;

    if (impersonateId && impersonateKey) {
      dispatch(setImpersonator({ impersonateId, impersonateKey }));
    } else if (!authUser?.exp && !isImpersonator) {
      sessionStorage.setItem('redirect', location.pathname + search);
      history.push(AUTH_ROUTES.TALENT_LOGIN);
    }
  }, [authUser, isImpersonator]);

  useEffect(() => {
    (async () => {
      const { impersonateId, impersonateKey } = impersonator;

      if (impersonateId && impersonateKey) {
        const data: Talent = await talentService.getSelfProfile(impersonateId);
        const { minimumDailyWage, acceptRoles, profilePhotoThumbnail } = data;
        const isLastStepFilled =
          acceptRoles &&
          acceptRoles.length > 0 &&
          minimumDailyWage !== undefined;
        dispatch(setPartialTalentProfile(data));
        dispatch(setIsTalentOnboarded({ isOnboarded: isLastStepFilled }));
        dispatch(
          setProfilePicture({ profilePictureUrl: profilePhotoThumbnail })
        );
      }
    })();
  }, [impersonator]);

  useEffect(() => {
    (async () => {
      if (authUser.exp || isImpersonator) {
        let nextKey: any = undefined;
        let applications: any[] = [];

        while (nextKey !== '') {
          const { data, key } = await jobService.getApplicationsByTalent(
            { type: 'current' },
            nextKey
          );
          applications = applications.concat(data);
          nextKey = key;
        }

        dispatch(setApplications({ applications, type: 'current' }));
      }
    })();
  }, [authUser.exp, isImpersonator]);

  useEffect(() => {
    (async () => {
      if (authUser.exp || isImpersonator) {
        const { data: result } = await activityService.listTalentActivities(
          'pending',
          talentId
        );
        if (result.pending) {
          const pending: Activity[] = result.pending;
          dispatch(setActivities({ pending }));
        }
      }
    })();
  }, [authUser.exp, isImpersonator]);

  useEffect(() => {
    (async () => {
      if (authUser.exp) {
        try {
          const settingsData = await settingsService.getSettings();
          dispatch(setPartialSettings(settingsData));
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [authUser.exp]);

  useEffect(() => {
    (async () => {
      if (isPWA) {
        const { subscription } = await getSubscription();
        if (subscription) dispatch(setIsSubscribeNotif({ isSubcribe: true }));
        else history.push(`/talent/prompt-notification`);
      }
    })();
  }, [isPWA]);

  useEffect(() => {
    (async () => {
      if (authUser.username) {
        const { subscription } = await getSubscription();

        if (subscription?.endpoint) {
          notificationService.updateAccessTime(
            { subscription, isPWA },
            authUser.username
          );
        }
      }
    })();
  }, [authUser]);

  return (
    <Page className='page-talent-dashboard'>
      <Div className='mobile-view'>
        <Menu />
        <InstallBanner />
        <OnboardingBanner />
        <Switch>
          {talentRoutes.map((props, key) => (
            <Route key={key} {...props} />
          ))}
        </Switch>
        {(isTalentOnboarded || isImpersonator) && (
          <BottomNavigation type='talent' />
        )}
      </Div>
    </Page>
  );
};

export default TalentDashboardPage;

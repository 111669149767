import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Div } from '../../../../framework';
import { useTalentService } from '../../../../service/talent.service';
import { useFileManageService } from '../../../../service/filemanage.service';
import { RootState } from '../../../../store/store';
import VideoGalleryManager from '../../../../component/VideoGalleryManager/VideoGalleryManager';
import {
  GalleryItem,
  MediaFile,
  MediaManagerItem,
} from '../../../../helper/type';
import { setPartialTalentProfile } from '../../../../store/user.slice';
import generateSearchParamString from '../../../../helper/generateSearchParamString';

import './Video.scss';

const { Title, Paragraph } = Typography;

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const MediaPage: FunctionComponent<Props> = ({ setStep }) => {
  const { authUser, talent, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state } = useLocation();
  const talentService = useTalentService();
  const fileManageService = useFileManageService();
  const edit = new URLSearchParams(search).get('edit');
  const [mediaItems, setMediaItems] = useState<MediaManagerItem[]>([]);
  const [photos, setPhotos] = useState<GalleryItem[]>([]);

  useEffect(() => {
    setStep(5);
  }, []);

  useEffect(() => {
    if (talent.gallery) {
      setMediaItems(convertToMediaItems(talent.gallery));
      setPhotos(talent.gallery.filter((item) => item.type === 'photo'));
    }
  }, [talent]);

  const convertToMediaItems = (items: GalleryItem[]): MediaManagerItem[] => {
    return items
      .filter((item) => item.type === 'video')
      .map((item) => ({
        url: item,
        isNew: false,
      }));
  };

  const convertToGalleryItems = (items: MediaManagerItem[]): GalleryItem[] => {
    return items.map((item) => ({
      ...item.url,
    }));
  };

  const uploadVideos = async (): Promise<GalleryItem[]> => {
    const uploadedItems: GalleryItem[] = [];
    const talentId = impersonator.impersonateId || authUser.username;

    for (let i = 0; i < mediaItems.length; i++) {
      const fileName = nanoid(10);
      if (mediaItems[i].file && mediaItems[i].isNew) {
        try {
          const { url: uploadedUrl } = await fileManageService.uploadFile({
            scope: 'talent',
            directory: talentId,
            file: (mediaItems[i].file as MediaFile).original,
            fileName,
          });

          uploadedItems.push({
            original: uploadedUrl,
            thumbnail:
              'https://heycastme-talent-media-prod.s3.ap-southeast-1.amazonaws.com/vid-placeholder.png',
            type: 'video',
          });
        } catch (err) {
          // ignore error
        }
      }
    }

    return uploadedItems;
  };

  const updateTalentGallery = async (newVideos: GalleryItem[]) => {
    const gallery = [
      ...newVideos,
      ...convertToGalleryItems(mediaItems.filter((item) => !item.isNew)),
      ...photos,
    ];

    const talentId = impersonator.impersonateId || authUser.username;
    await talentService.updateProfile({ gallery }, talentId);
    dispatch(setPartialTalentProfile({ gallery }));
  };

  const handleSubmit = async () => {
    const newVideos = await uploadVideos();
    await updateTalentGallery(newVideos);

    const location = {
      pathname: '/talent/profile/experience',
      search: generateSearchParamString({ edit }),
      state,
    };

    history.push(location);
  };

  return (
    <Div className='page-talent-form-video'>
      <Title level={3}>Casting Videos</Title>
      <Paragraph>
        A standard casting video should include self introduction and acting for
        4 expressions (happy, angry, sad and surprised). Here is{' '}
        <a
          href='https://drive.google.com/file/d/1vFg3PQa8Fg4DDlYUSMlHF_h9MJ6Hr1XP/view?usp=sharing'
          target='_blank'
          rel='noreferrer'
        >
          an example
        </a>
        .
      </Paragraph>
      <VideoGalleryManager value={mediaItems} onChange={setMediaItems} />
      <Paragraph type='secondary' className='help-message'>
        It is not required to upload a casting video, however having a casting
        video would definitely helps to demonstrate your acting skill better
      </Paragraph>
      <Div mv='l'>
        <Div mb='m'>
          <Button onClick={handleSubmit} type='primary' htmlType='submit' block>
            {mediaItems.filter((item) => item.isNew).length
              ? 'Upload Videos & Next'
              : 'Next'}
          </Button>
        </Div>
        <Button
          block
          onClick={() => {
            const location = {
              pathname: '/talent/profile/picture',
              search: generateSearchParamString({ edit }),
              state,
            };

            history.push(location);
          }}
        >
          Back
        </Button>
      </Div>
    </Div>
  );
};

export default MediaPage;

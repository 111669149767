import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import InvoiceDetails from '../../Invoice/InvoiceDetails';
import { useAccountingService } from '../../../service/accounting.service';
import { RootState } from '../../../store/store';
import {
  setSelfBilledInvoice,
  setSelfBilledInvoices,
} from '../../../store/talent.slice';
import { Project, Role } from '../../../helper/type';
import { useJobService } from '../../../service/job.service';

import './InvoiceDetails.scss';

const InvoiceDetailsPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { projectId, invoiceId } = useParams<{
    projectId: string;
    invoiceId: string;
  }>();
  const history = useHistory();
  const accountingService = useAccountingService();
  const jobsService = useJobService();
  const { talent } = useSelector((state: RootState) => state.user);
  const { mappedSelfBilledInvoices } = useSelector(
    (state: RootState) => state.talent
  );

  const [roles, setRoles] = useState<Role[]>();
  const [project, setProject] = useState<Project>();
  const currentInvoice =
    mappedSelfBilledInvoices[projectId] &&
    mappedSelfBilledInvoices[projectId][invoiceId];

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    fetchJobData();
  }, []);

  useEffect(() => {
    if (
      !mappedSelfBilledInvoices[projectId] ||
      !mappedSelfBilledInvoices[projectId][invoiceId]
    ) {
      fetchTalentInvoices();
    }
  }, []);

  const fetchTalentInvoices = async () => {
    if (!talent.id) return;

    const { data } = await accountingService.listSelfBilledInvoicesByPayeeId(
      talent.id
    );
    dispatch(setSelfBilledInvoices(data));
  };

  const fetchJobData = async () => {
    const res = await jobsService.getRolesByProject(projectId);
    setRoles(res);
    const project = await jobsService.getProject(projectId, 'false');
    setProject(project);
  };

  const handleSubmitInvoice = async () => {
    if (!currentInvoice.id) return;

    const { beneficieryName = talent.name ?? '', channelCode = 'MY_DUITNOW' } =
      talent.paymentMethod ?? {};
    const updatedInvoice = await accountingService.submitAllowances(
      currentInvoice.id,
      {
        paymentMethod: {
          beneficieryName,
          channelCode,
          accountNumber: `${talent.phoneNumberPrefix}${talent.phoneNumber}`,
        },
        items: currentInvoice.items?.filter(
          ({ description }) => !description.startsWith('Talent Booking:')
        ),
      }
    );
    dispatch(setSelfBilledInvoice(updatedInvoice));

    setTimeout(() => history.push('/talent/applications?tab=unpaid'), 1000);
  };

  return (
    <Page className='page-talent-invoice-details'>
      <Helmet>
        <title>Invoice - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/talent/applications'>All Applications</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to='/talent/applications?tab=unpaid'>Unpaid</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{currentInvoice?.id}</Breadcrumb.Item>
      </Breadcrumb>

      {currentInvoice && project && (
        <InvoiceDetails
          type='talent'
          invoice={currentInvoice}
          roles={roles}
          project={project}
          onSubmitInvoice={handleSubmitInvoice}
        />
      )}
    </Page>
  );
};

export default InvoiceDetailsPage;

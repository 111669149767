import { Tooltip, Typography, message } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format as formatDateString, parseISO } from 'date-fns';
import {
  CalendarOutlined,
  DollarOutlined,
  TranslationOutlined,
  PushpinOutlined,
  EyeOutlined,
  LogoutOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  CopyrightOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Div } from '../../../framework';
import ArticleSvg from '../../../framework/Icon/ArticleSvg';
import { statusText } from '../../../helper/constants';
import {
  calcRolePayment,
  getRolePaymentRate,
} from '../../../helper/job/calcRolePayment';
import {
  Activity,
  JobHistory,
  Project,
  SelfBilledInvoice,
  SelfBilledInvoiceStatus,
} from '../../../helper/type';
import { openModal } from '../../../store/app.slice';
import { RootState } from '../../../store/store';
import { featureToggle } from '../../../../config/app.config';

import './ApplicationCard.scss';

const { Text } = Typography;

type Props = {
  job: JobHistory & {
    latestActivity?: Activity;
    applicantStatusKey?: string;
    viewCount?: {
      profile: number;
      video: number;
    };
  };
  allowWithdraw: boolean;
  isUnpaid?: boolean;
  pastApplication?: boolean;
};

const ApplicationCard: FunctionComponent<Props> = ({
  job,
  allowWithdraw,
  isUnpaid,
  pastApplication,
}) => {
  const dispatch = useDispatch();
  const { mappedSelfBilledInvoices } = useSelector(
    (state: RootState) => state.talent
  );

  const [, contextHolder] = message.useMessage();
  const [talentInvoice, setTalentInvoice] = useState<SelfBilledInvoice>();
  const [showMore, setShowMore] = useState(false);

  const enableSubmitAllowance =
    isUnpaid && talentInvoice?.status === SelfBilledInvoiceStatus.DRAFT;
  const invoicePaymentStatus =
    talentInvoice?.status === SelfBilledInvoiceStatus.PAID ? 'paid' : 'unpaid';

  useEffect(() => {
    if (!featureToggle.invoice) return;

    const invoice =
      mappedSelfBilledInvoices[job.project.id] &&
      Object.values(mappedSelfBilledInvoices[job.project.id]).find(
        (val) => val.payeeId === job.talentId
      );

    if (invoice) setTalentInvoice(invoice);
  }, [mappedSelfBilledInvoices[job.project.id]]);

  const handleWithdrawApplication = async () => {
    const { role, project } = job;
    if (role && project) {
      dispatch(
        openModal({
          modal: 'talent-withdraw-application',
          config: {
            context: {
              hirerId: project.hirerId,
              roleId: role.id,
              applicationId: job.talentId,
              projectId: project.id,
              projectTitle: project.title,
              roleName: role.name,
              applicantStatus: job.status,
            },
          },
        })
      );
    }
  };

  const renderStatus = () => {
    const {
      latestActivity,
      applicantStatusKey,
      isNotSelected,
      isNotSelectedTimestamp,
    } = job;

    const latestActivityTimestamp =
      latestActivity?.lastUpdateAt ?? latestActivity?.createdAt ?? '';

    if (
      isNotSelected &&
      isNotSelectedTimestamp &&
      isNotSelectedTimestamp > latestActivityTimestamp
    ) {
      return (
        <Div noGrow className='status-tag'>
          Not Selected
        </Div>
      );
    }

    const tooltip =
      job?.status === 'shortlisted'
        ? "You're included on a list of final candidates for this role, but not selected yet. Pending final selection."
        : '';

    const getStatusText = () => {
      if (job.status === 'withdrawn') return statusText['withdrawn'];
      if (job.status === 'shortlisted') return statusText['shortlisted'];

      return statusText[applicantStatusKey ?? 'none'];
    };

    return (
      <Tooltip title={tooltip}>
        <Div noGrow className='status-tag'>
          {getStatusText()}
        </Div>
      </Tooltip>
    );
  };

  const renderViewCount = () =>
    job.viewCount &&
    (job.viewCount.profile > 0 || job.viewCount.video > 0) && (
      <Div pb='xs' flex>
        <EyeOutlined
          className='icon text-success'
          style={{ marginTop: '0.25rem' }}
        />
        <Div>
          {job.viewCount.profile > 0 && (
            <Div>
              <span className='text-success bold'>
                Profile viewed {job.viewCount.profile} times
              </span>
            </Div>
          )}
          {job.viewCount.video > 0 && (
            <Div>
              <span className='text-success bold'>
                Casting video played {job.viewCount.video} times
              </span>
            </Div>
          )}
        </Div>
      </Div>
    );

  const renderShootingDate = (project: Project) => {
    if (project.isNotConsecutiveDate) {
      return (
        <>
          {project.shootDates && project.shootDates.length > 0
            ? project.shootDates
                .map((date) => formatDateString(parseISO(date), 'dd MMM yyy'))
                .join(', ')
            : 'TBC'}
        </>
      );
    }

    return (
      <>
        {project.shootDate?.from
          ? formatDateString(parseISO(project.shootDate.from), 'dd MMM yyy')
          : 'TBC'}
        {project.shootDate?.to.length >= 0 &&
        project.shootDate?.to !== project.shootDate?.from
          ? ` - ${formatDateString(
              parseISO(project.shootDate.to),
              'dd MMM yyy'
            )}`
          : ''}
      </>
    );
  };

  return (
    <Div className='application-card'>
      {contextHolder}

      <Div className='project-roles'>
        <Div key={job?.roleId} flex p='s' pl='l' pr='l' className='role-title'>
          <Div>
            {job?.project?.title} - {job?.role?.name}
          </Div>
          {renderStatus()}
        </Div>
        <Div p='xs' pl='l' pr='l' className='role-details'>
          {renderViewCount()}

          <Div pb='xs' className='role-description'>
            Job Description:{' '}
            {job?.project.description &&
              job?.project.description
                .split('\n')
                .map((d, index) => <Div key={`${index}${d}`}>{d}</Div>)}
          </Div>

          <Div pb='xs'>
            <PushpinOutlined className='icon' />
            <span className='bold'>{job?.project?.shootLocation}</span>
          </Div>
          <Div pb='xs'>
            <CalendarOutlined className='icon' />
            <span className='bold'>{renderShootingDate(job.project)}</span>
          </Div>

          <Div pb='xs' className='role-description'>
            Role Description: {job?.role?.description}
          </Div>

          {job?.role?.languages?.length > 0 && (
            <Div pb='xs' className='role-requirements'>
              <TranslationOutlined className='icon' />
              {job?.role?.languages?.join(', ')}
            </Div>
          )}

          <Div pb='xs'>
            <DollarOutlined className='icon' />
            <span className='bold'>
              RM{' '}
              {calcRolePayment((job?.rolePayment ?? job?.role?.payment) || 0)}
            </span>
            ,{' '}
            <span className='role-description'>
              nett after{' '}
              {getRolePaymentRate(
                (job?.rolePayment ?? job?.role?.payment) || 0
              ) * 100}
              % platform fee
            </span>
            {featureToggle.invoice && isUnpaid && talentInvoice && (
              <Button
                ghost
                disabled={!enableSubmitAllowance}
                className='btn-submit-allowance'
                to={`/talent/project/${job.project.id}/invoice/${talentInvoice.id}`}
              >
                {!enableSubmitAllowance
                  ? 'Allowance submitted!'
                  : 'Submit Allowance'}
              </Button>
            )}
          </Div>

          {featureToggle.invoice && talentInvoice && (
            <Div pb='xs'>
              <CheckCircleOutlined className='icon' />
              Payment status:{' '}
              <span className='bold capitalize'>{invoicePaymentStatus}</span>
            </Div>
          )}

          {pastApplication && !showMore && (
            <Button
              ghost
              className='btn-show-more'
              onClick={() => setShowMore(true)}
            >
              View Details
            </Button>
          )}

          {showMore && (
            <>
              {job.project.contractual !== undefined && (
                <Div className='project-info' pb='xs'>
                  <CheckSquareOutlined className='icon' />
                  {!job.project.contractual ? 'Non-Contractual' : 'Contractual'}
                </Div>
              )}
              {job.project.paymentTerm && (
                <Div className='project-info' pb='xs'>
                  <FieldTimeOutlined className='icon' />
                  {job.project.paymentTerm?.value}{' '}
                  {job.project.paymentTerm?.period} payment term
                </Div>
              )}
              {job.project.media && job.project.media.length > 0 && (
                <Div className='project-info' pb='xs'>
                  <VideoCameraOutlined className='icon' />
                  {job.project.media.join(', ')}
                </Div>
              )}

              {job.project.mediaUsageCountries &&
                job.project.mediaUsageCountries.length > 0 && (
                  <Div className='project-info' pb='xs'>
                    <GlobalOutlined className='icon' />
                    {job.project.mediaUsageCountries.join(', ')}
                  </Div>
                )}
              {job.project.usageDuration &&
                (job.project.usageDuration.perpetual !== undefined ||
                  job.project.usageDuration.duration !== undefined) && (
                  <Div className='project-info' pb='xs'>
                    <CopyrightOutlined className='icon' />
                    {job.project.usageDuration?.perpetual
                      ? 'Perpetual'
                      : job.project.usageDuration?.duration ?? ''}
                  </Div>
                )}

              <Div pb='xs' className='role-requirements'>
                <span className='capitalize bold'>{job.role.type}</span>:{' '}
                <span className='capitalize'>{job.role.gender}</span>,{' '}
                <span className='capitalize'>
                  {job.role.raceEthnicity
                    ? job.role.raceEthnicity.join(', ')
                    : job.role.race}
                </span>
                , {job.role.ageFrom}-{job.role.ageTo} years old
              </Div>
            </>
          )}
        </Div>
        <Div className='action' flex>
          {allowWithdraw ? (
            <button onClick={handleWithdrawApplication}>
              <LogoutOutlined />
              <Text>Withdraw</Text>
            </button>
          ) : job.status === 'withdrawn' ? (
            <Div pv='m' ph='l'>
              Withdrawal Reason:
              <br />
              {job.latestActivity?.withdrawReason}
            </Div>
          ) : null}

          {featureToggle.invoice &&
            pastApplication &&
            talentInvoice &&
            talentInvoice.status !== SelfBilledInvoiceStatus.DRAFT && (
              <Div flex>
                <Div></Div>
                <a
                  href={`/talent/project/${job.project.id}/invoice/${talentInvoice.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ArticleSvg />
                  <Text>View Invoice</Text>
                </a>
              </Div>
            )}
        </Div>
      </Div>
    </Div>
  );
};

export default ApplicationCard;

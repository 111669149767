import { Empty, List } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonProjectCard from '../../../component/Skeleton/ProjectCard';
import { JobHistory, SelfBilledInvoiceStatus } from '../../../helper/type';

import { Div } from '../../../framework';
import ApplicationCard from './ApplicationCard';
import { RootState } from '../../../store/store';
import { useAccountingService } from '../../../service/accounting.service';
import { setSelfBilledInvoices } from '../../../store/talent.slice';
import { useApplications } from '../../../hook/useApplications';

type HistoryData = JobHistory & { loading: boolean };

const UnpaidTabContent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { talent } = useSelector((state: RootState) => state.user);
  const { mappedSelfBilledInvoices } = useSelector(
    (state: RootState) => state.talent
  );
  const { loadStack } = useSelector((state: RootState) => state.app);
  const [data, setData] = useState<HistoryData[]>([]);

  const accountingService = useAccountingService();

  const isLoadingApplication = loadStack.includes('job/application/list');

  const { mappedPastApplications } = useApplications({
    initLoadAll: true,
    type: 'past',
    withdraw: false,
  });

  useEffect(() => {
    const pastApplications = Object.values(mappedPastApplications);
    if (pastApplications.length === 0) return;

    const unpaidApplications: HistoryData[] = [];
    pastApplications.forEach((application) => {
      const { project, talentId } = application;
      const invoice =
        mappedSelfBilledInvoices[project.id] &&
        Object.values(mappedSelfBilledInvoices[project.id]).find(
          (invoice) =>
            invoice.payeeId === talentId &&
            invoice.status !== SelfBilledInvoiceStatus.PAID
        );

      if (invoice) {
        unpaidApplications.push({ ...application, loading: false });
      }
    });

    setData(unpaidApplications);
  }, [mappedSelfBilledInvoices, mappedPastApplications]);

  const loadInvoices = async () => {
    if (!talent.id) return;

    const { data } = await accountingService.listSelfBilledInvoicesByPayeeId(
      talent.id
    );
    dispatch(setSelfBilledInvoices(data));
  };

  useEffect(() => {
    loadInvoices();
  }, []);

  return !isLoadingApplication && data.length === 0 ? (
    <Div className='zero-state'>
      <Empty description={<>No unpaid applications</>} />
    </Div>
  ) : (
    <List
      className='project-list'
      loading={isLoadingApplication}
      dataSource={data}
      itemLayout='vertical'
      footer={
        !isLoadingApplication &&
        data.length > 0 && (
          <div className='end-of-list'>
            <span>That&apos;s all for unpaid jobs!</span>
          </div>
        )
      }
      renderItem={(item) => {
        return (
          <SkeletonProjectCard key={item.roleId} loading={item.loading}>
            <List.Item key={item.roleId}>
              <ApplicationCard
                key={item.roleId}
                job={item}
                allowWithdraw={false}
                isUnpaid
                pastApplication={false}
              />
            </List.Item>
          </SkeletonProjectCard>
        );
      }}
    ></List>
  );
};

export default UnpaidTabContent;

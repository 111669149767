import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Typography, Select, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import { Button, Div } from '../../../../framework';
import { StepperInput } from '../../../../framework/StepperInput/StepperInput';
import { setFlyoutOpen, setMenuMode } from '../../../../store/app.slice';
import { InvoiceItemBase, SelfBilledInvoice } from '../../../../helper/type';
import { setSelfBilledInvoice } from '../../../../store/talent.slice';
import MealForm from './MealForm';
import { RootState } from '../../../../store/store';
import { useFileManageService } from '../../../../service/filemanage.service';
import { getInvoiceAllowanceDesc } from '../../../../helper/invoice';

import './SubmitAllowance.scss';

const { Title } = Typography;
const { Option } = Select;

type UploadedReceipt = { id: string; file?: File; url?: string };
type RoleAllowance = {
  label: string;
  roleId: string;
  allowanceType: string;
  hourRate?: number | undefined;
  maxAmount?: number | undefined;
  amount?: number | undefined;
};

const SubmitAllowance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, state: locationState } = useLocation<{
    roleAllowances: RoleAllowance[];
  }>();
  const { invoiceId, mode } = useParams<{
    invoiceId: string;
    mode: 'new' | 'edit' | 'view';
  }>();
  const projectId = new URLSearchParams(search).get('projectId') || '';
  const itemIdx = new URLSearchParams(search).get('idx') || '';
  const { talent } = useSelector((state: RootState) => state.user);
  const { mappedSelfBilledInvoices } = useSelector(
    (state: RootState) => state.talent
  );
  const fileManageService = useFileManageService();

  const [currentInvoice, setCurrentInvoice] = useState<SelfBilledInvoice>();
  const [type, setType] = useState<string>();
  const [otHour, setOtHour] = useState(1);
  const [uploadedReceipt, setUploadedReceipt] = useState<UploadedReceipt[]>([]);
  const [mealAmount, setMealAmount] = useState(0);

  const { roleAllowances } = locationState;
  const invoicePagePath = `/talent/project/${projectId}/invoice/${invoiceId}`;

  const getTargetRoleAllowance = (
    targetDesc: string,
    roleAllowances: RoleAllowance[],
    talentName: string
  ) =>
    roleAllowances.find(
      (allw) => getInvoiceAllowanceDesc(allw.label, talentName) === targetDesc
    );

  const selectedAllowanceOption = roleAllowances.find(
    (allw) => allw.label === type
  );
  const itemToEdit =
    mode !== 'new' && currentInvoice?.items
      ? currentInvoice?.items[Number(itemIdx)]
      : undefined;

  const enableSubmit = useMemo(() => {
    if (!type || !selectedAllowanceOption) return false;

    if (type.startsWith('Meal')) {
      return (
        selectedAllowanceOption.maxAmount &&
        mealAmount <= selectedAllowanceOption.maxAmount
      );
    }

    return true;
  }, [type, mealAmount]);

  const allowanceOptions = useMemo(() => {
    if (mode !== 'new' && itemToEdit && talent.name) {
      const editAllw = getTargetRoleAllowance(
        itemToEdit.description,
        roleAllowances,
        talent.name
      );
      return editAllw ? [editAllw] : [];
    }

    const submittedAllowance =
      currentInvoice?.items
        ?.filter((allw) => allw.description.startsWith('Allowance:'))
        .map((allw) => allw.description) ?? [];

    return (roleAllowances ?? []).filter(
      ({ label }) =>
        !submittedAllowance.includes(
          getInvoiceAllowanceDesc(label, talent.name ?? '')
        )
    );
  }, [mode, itemToEdit, currentInvoice, roleAllowances, talent.name]);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    if (!projectId || !roleAllowances) {
      history.push('/talent/applications?tab=unpaid');
      return;
    }
  }, []);

  useEffect(() => {
    if (mode === 'new' || !itemToEdit || !talent.name) return;

    const targetAllw = getTargetRoleAllowance(
      itemToEdit.description,
      roleAllowances,
      talent.name
    );
    if (!targetAllw) return;

    setType(targetAllw.label);
    setUploadedReceipt(
      itemToEdit.attachments?.map((att) => ({ id: nanoid(), url: att.url })) ??
        []
    );
    setMealAmount(targetAllw.allowanceType === 'Meal' ? itemToEdit.amount : 0);

    const hourRate = targetAllw.hourRate ?? 0;
    setOtHour(
      targetAllw.allowanceType === 'OT' && hourRate > 0
        ? itemToEdit.amount / hourRate
        : 1
    );
  }, [itemToEdit]);

  useEffect(() => {
    const invoice =
      mappedSelfBilledInvoices[projectId] &&
      mappedSelfBilledInvoices[projectId][invoiceId];
    if (invoice) setCurrentInvoice(invoice);
  }, [projectId, invoiceId, mappedSelfBilledInvoices[projectId]]);

  const calcAllowanceAmount = () => {
    if (!type) return 0;

    if (type.startsWith('OT')) {
      return (selectedAllowanceOption?.hourRate ?? 0) * otHour;
    }
    if (type.startsWith('Meal')) {
      return mealAmount;
    }
    if (type.startsWith('Travel/Transportation')) {
      return selectedAllowanceOption?.amount ?? 0;
    }

    return 0;
  };
  const processAttachments = async () => {
    if (!currentInvoice) return;

    const attachments: InvoiceItemBase['attachments'] = [];
    if (uploadedReceipt.length > 0) {
      const uploadTasks = uploadedReceipt.map((receipt) => {
        if (receipt.file) {
          return fileManageService.uploadFile({
            scope: 'invoice',
            directory: currentInvoice.id || '',
            file: receipt.file,
          });
        }

        return { url: receipt.url || '' };
      });

      const uploadResults = await Promise.allSettled(uploadTasks);
      uploadResults.forEach((res) => {
        if (res.status === 'fulfilled') {
          attachments.push({ url: res.value.url });
        }
      });
    }

    return attachments;
  };

  const handleAddNewAllowance = async (reset: boolean) => {
    if (!enableSubmit || !currentInvoice || !type || !talent.name) return;

    const attachments = await processAttachments();

    dispatch(
      setSelfBilledInvoice({
        ...currentInvoice,
        items: [
          ...(currentInvoice.items ?? []),
          {
            description: getInvoiceAllowanceDesc(type, talent.name),
            amount: calcAllowanceAmount(),
            attachments,
          },
        ],
      })
    );

    if (reset && allowanceOptions.length > 1) {
      resetForm();
      return;
    }

    history.push(invoicePagePath);
  };
  const handleSaveEditAllowance = async () => {
    if (!enableSubmit || !itemToEdit || !currentInvoice) return;

    const attachments = await processAttachments();

    dispatch(
      setSelfBilledInvoice({
        ...currentInvoice,
        items: [
          ...(currentInvoice.items ?? []).map((item, idx) =>
            idx === Number(itemIdx)
              ? {
                  description: itemToEdit.description,
                  amount: calcAllowanceAmount(),
                  attachments,
                }
              : item
          ),
        ],
      })
    );

    history.push(invoicePagePath);
  };
  const handleDeleteAllowance = async () => {
    if (!currentInvoice) return;

    dispatch(
      setSelfBilledInvoice({
        ...currentInvoice,
        items: currentInvoice.items?.filter(
          (item, idx) => idx !== Number(itemIdx)
        ),
      })
    );

    history.push(invoicePagePath);
  };

  const resetForm = () => {
    setType(undefined);
    setOtHour(1);
    setUploadedReceipt([]);
    setMealAmount(0);
  };

  return (
    <Div className='page-talent-submit-allowance'>
      <Div flex className='title-container'>
        <Title level={4} className='title'>
          {mode === 'new' ? 'add' : mode} Allowance
        </Title>
        <Link to={invoicePagePath} className='btn-close'>
          <CloseOutlined />
        </Link>
      </Div>

      <hr />

      <Div mv='l'>
        <Div className='bold'>Type of allowance</Div>
        <Select
          className='block select-allowance-type'
          value={type}
          onChange={setType}
          disabled={mode !== 'new' && Boolean(type)}
        >
          {allowanceOptions.map(({ label }) => (
            <Option key={label} value={label}>
              {label}
            </Option>
          ))}
        </Select>
      </Div>

      {type?.startsWith('Travel/Transportation') && (
        <Div>
          <Div className='bold'>Amount</Div>
          <Input
            disabled
            value={`MYR ${calcAllowanceAmount()}`}
            style={{ color: 'black', fontWeight: 600 }}
          />
        </Div>
      )}

      {type?.startsWith('OT') && (
        <>
          <Div mv='l'>
            <Div className='bold'>Hours</Div>
            <StepperInput
              value={otHour}
              onChange={setOtHour}
              min={1}
              disabled={mode === 'view'}
            />
          </Div>
          <Div>
            <Div className='bold'>Amount</Div>
            <Input
              disabled
              value={`MYR ${calcAllowanceAmount()}`}
              style={{ color: 'black', fontWeight: 600 }}
            />
          </Div>
        </>
      )}

      {type?.startsWith('Meal') && (
        <MealForm
          maxAmount={selectedAllowanceOption?.maxAmount ?? 0}
          uploadedReceipt={uploadedReceipt}
          mealAmount={mealAmount}
          onUploadReceipt={(file) => {
            setUploadedReceipt(
              uploadedReceipt.concat([{ file, id: nanoid() }])
            );
          }}
          onRemoveReceipt={(id) => {
            setUploadedReceipt(
              uploadedReceipt.filter((receipt) => receipt.id !== id)
            );
          }}
          onChangeMealAmount={setMealAmount}
          disabled={mode === 'view'}
        />
      )}

      {mode === 'new' && (
        <Div mv='xl'>
          <Button
            solid
            block
            style={{ marginBottom: '1rem' }}
            onClick={() => handleAddNewAllowance(false)}
            disabled={!enableSubmit}
          >
            Submit
          </Button>
          <Button
            block
            onClick={() => handleAddNewAllowance(true)}
            disabled={!enableSubmit || allowanceOptions.length <= 1}
          >
            Submit & Add Another Allowance
          </Button>
        </Div>
      )}
      {mode === 'edit' && (
        <Div mv='xl'>
          <Button
            solid
            block
            style={{ marginBottom: '1rem' }}
            onClick={handleSaveEditAllowance}
            disabled={!enableSubmit}
          >
            Save
          </Button>
          <Button block ghost onClick={handleDeleteAllowance}>
            Delete Allowance
          </Button>
        </Div>
      )}
    </Div>
  );
};

export default SubmitAllowance;

import React, { FunctionComponent, useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Div } from '../../../framework';
import talentRoutes from '../../../route/talentProfileForm.route';
import { useTalentService } from '../../../service/talent.service';
import { RootState } from '../../../store/store';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import { setPartialTalentProfile } from '../../../store/user.slice';
import { Steps } from '../../../framework';

import './Form.scss';

const steps = [
  { title: 'Contact Details' },
  { title: 'About Me' },
  { title: 'Skills' },
  { title: 'Photos' },
  { title: 'Profile Picture' },
  { title: 'Casting Videos' },
  { title: 'Experience' },
  { title: 'KOL / Influencer' },
  { title: 'Artiste / KOL Manager' },
  { title: 'Job Preference' },
];

const Form: FunctionComponent = () => {
  const { search, pathname } = useLocation();
  const talentService = useTalentService();
  const tid = new URLSearchParams(search).get('tid');
  const [step, setStep] = useState(0);
  const { authUser, talent } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const shouldShowStepper = pathname !== '/talent/profile';

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    (async () => {
      if (authUser.id && !talent.name && !tid) {
        const data = await talentService.getSelfProfile(authUser.username);
        dispatch(setPartialTalentProfile(data));
      } else if (tid) {
        const data = await talentService.getFullProfile(tid);
        dispatch(setPartialTalentProfile(data));
      }
    })();
  }, [authUser]);

  return (
    <Page className='page-talent-form talent-page'>
      {shouldShowStepper && <Steps current={step} steps={steps} />}
      {tid ? (
        <Div
          noGrow
          style={{
            width: '100%',
            marginBottom: '16px',
            display: 'flex',
            padding: '0 32px',
          }}
        >
          <label style={{ marginRight: '16px' }}>User ID</label>
          <Input size='large' value={tid} disabled />
        </Div>
      ) : null}
      <Switch>
        {talentRoutes.map((props, key) => {
          // eslint-disable-next-line react/prop-types
          const { component, ...restProps } = props;

          return component ? (
            <Route key={key} {...restProps}>
              {React.createElement(
                component as FunctionComponent,
                { setStep } as any
              )}
            </Route>
          ) : (
            <Route key={key} {...restProps} />
          );
        })}
      </Switch>
      <div className='spacer'></div>
    </Page>
  );
};

export default Form;
export { steps as TalentProfileSections };
